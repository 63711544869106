import React from 'react';

export default function InputPanel({heading, ...props}) {
    return (
    <>
    <h2>{heading}</h2>
    <div className="component-input-container">
        {props.children}
    </div>
    </>);
}