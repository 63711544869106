import React, {useState, useEffect} from 'react';
import DropZone from '../components/DropZone';
import BatchResults from '../components/BatchResults';
import RemixInput from '../components/RemixInput';
import InputPanel from '../components/InputPanel';
import OutputPanel from '../components/OutputPanel';
import { removeExtension } from '../utils/dotify';

export default function Remix(props) {
    const [fileList, setFileList] = useState([]);
    const [fileData, setFileData] = useState([]);
    const [filtered, setFiltered] =  useState([]);
    const [matchFound, setMatchFound] = useState(false);
    const showFileList = (files) => setFileList([...files]);

    const clearFilter = () => {
        setFiltered([]);
    };

    const clearList = () => {
        setFiltered([]);
        setFileData([]);
        setFileList([]);
        props.reset();
    };

    const undoChange = (files) => {
        setFileData([...files]); 
        clearFilter();
    };

    function filterResults(status) {
        if (status === 'clear') {
            clearFilter();
        } else {
            const filteredList = fileData.filter( file => file.status === status);
            setFiltered(filteredList);
        }
    }

    function getActiveData() {
        let payload = null;
        if (filtered.length) {
            payload = filtered;
        } else if (fileData.length) {
            payload = fileData;
        } else if (fileList.length) {
            payload = fileList;
        } else {
            payload = [];
        }
        return payload;
    }

    function replaceAll(from, to) {
        const data = [];
        let isMatch = false;
        let old = null;
        try {
            old = new RegExp(from);
        } catch(e) {
            return null;
        }

        fileData.forEach( file => {
            const match = file.name.match(old);
            if (match) isMatch = true;
            const capture = match ? match[match.length - 1] : null;
            const newName = capture ? file.name.replace(capture, to) : file.name;
            data.push({
                name: newName,
                status: match ? 'modified' : 'unchanged',
                content: file.content,
            });
        });
        setFileData(data);
        setMatchFound(isMatch);
    }
    
    useEffect(() => {
        const data = [];
        fileList.forEach( file => {
            data.push({
                name: removeExtension(file.name),
                status: "unchanged",
                content: ("content" in file ? file.content : file)
            });
        });
        setFileData(data);
    // eslint-disable-next-line
    }, [fileList]);

    useEffect(() => {
        if (props.transferredData.length) showFileList(props.transferredData);
    }, [props.transferredData]);

    return (
        <>
        <InputPanel heading={"REmix"} >
            <RemixInput 
                matchFound={matchFound} 
                replace={replaceAll} 
                undo={undoChange}
                clear={clearList} 
                fileData={getActiveData()} 
                isFiltered={filtered.length > 0}
            />
            <DropZone callback={showFileList}/>
        </InputPanel>
        <OutputPanel heading={"Batch Results"}>
            <BatchResults 
                isFiltered={filtered.length > 0} 
                filter={filterResults} 
                transfer={() => props.transfer(getActiveData(), "dotify")}
                clear={clearList} 
                show={{legend: true, controls: true}} 
                fileData={getActiveData()}
            />
        </OutputPanel>
        </>
    );
}