import JSZip from 'jszip';
import { saveAs } from 'file-saver';

function generateZip(zip, setIsLoading) {
    zip.generateAsync({
        type: "blob"
    }).then( function(content) {
        saveAs(content, "dotified.zip");
        setIsLoading(false);
    }).catch( e => {
        setIsLoading(false);
        console.log("Error. Please reload and try again.");
    });

}

function downloadFiles(setIsLoading, fileList) {
    setIsLoading(true);
    let zip = new JSZip();

    for (const file of fileList) {
        let fileName = file.status === 'error' ? `INVALID-FORMAT-${file.name}` : file.name;
        fileName += '.pdf';

        file.content.arrayBuffer()
        .then( content => {
            zip.file(fileName, content);
        })
        .catch( e => {
            setIsLoading(false);
            console.log("Error reading blob.");
        });
    }
    setTimeout(generateZip, 1500, zip, setIsLoading);
}

export default downloadFiles;
