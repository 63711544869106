import React, {useState} from 'react';
import BatchLegend from './BatchLegend';
import BatchControls from './BatchControls';
import BatchItem from './BatchItem';
import downloadFiles from '../utils/download';
import { getStatusCount } from '../utils/helpers';

export default function BatchResults(props) {
    const [isLoading, setIsLoading] = useState(false);

    return (
        <>
        <div className="batch-results-header">
            <p className="main-container-subheader file-count">File Count: {props.fileData.length}</p>
            {props.fileData.length > 0 ?
            <>
            {props.show.legend ?
             <BatchLegend 
                counts={getStatusCount(props.fileData)}
                isFiltered={props.isFiltered}
                filter={props.filter}
             />
            :
            null}
            {props.show.controls ? 
             <BatchControls 
                transfer={props.transfer}
                clear={props.clear} 
                download={() => downloadFiles(setIsLoading, props.fileData)}
                isLoading={isLoading}
             />
            :
            null}
            </>
            :
            null}
        </div>
        <div className="component" id="batch-file-list">
            <BatchItem fileData={props.fileData} />
        </div>
        </>
    );
}