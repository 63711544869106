import React from 'react';
import { FaRegTimesCircle, FaRegArrowAltCircleDown, FaRegClone } from 'react-icons/fa';

export default function BatchControls({transfer, clear, download, isLoading}) {
    return (
        <div className="batch-results-controls">
            <FaRegClone onClick={transfer} className="controller--icon icon-send" /> 
            <FaRegTimesCircle onClick={clear} className="controller--icon icon-clear" />
            <FaRegArrowAltCircleDown onClick={download} className={`controller--icon icon-download ${isLoading ? 'downloading' : ''}`} />
        </div>
    );
}