import React from 'react';

export default function OutputPanel({heading, ...props}) {
    return (
        <>
        <h2 id="dotify-batch-header">{heading}</h2>
        <div className="component-output-container">
            {props.children}
        </div>
        </>
    );
}