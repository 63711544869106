import React, {useState, useEffect} from 'react';
import DotifySingle from '../components/DotifySingle';
import DropZone from '../components/DropZone';
import BatchResults from '../components/BatchResults';
import InputPanel from '../components/InputPanel';
import OutputPanel from '../components/OutputPanel';
import dotifyTitle from '../utils/dotify';

export default function Dotify(props) {
    const [fileList, setFileList] = useState([]);
    const [fileData, setFileData] = useState([]);
    const [filtered, setFiltered] = useState([]);

    const showFileList = (files) => {
        setFileList([...files]);
        setFileData([]);
        setFiltered([]);
    };

    const clearList = () => {
        setFiltered([]);
        setFileData([]);
        setFileList([]);
        props.reset();
    };

    function filterResults(status) {
        if (status === 'clear') {
            setFiltered([]);
        } else {
            const filteredList = fileData.filter( file => file.status === status);
            setFiltered(filteredList);
        }
    }

    function getActiveData() {
        let payload = null;
        if (filtered.length) {
            payload = filtered;
        } else if (fileData.length) {
            payload = fileData;
        } else {
            payload = fileList;
        }
        return payload;
    }


    useEffect(() => {
        const data = [];
        fileList.forEach( file => {
            let [title, status] = dotifyTitle(file.name);
            data.push( {
                name: title, 
                status: status,
                content: ("content" in file ? file.content : file)
            });
        });
        setFileData(data);
    // eslint-disable-next-line
    }, [fileList]);

    useEffect(() => {
        if (props.transferredData.length) showFileList(props.transferredData);
    }, [props.transferredData]);

    return (
        <>
        <InputPanel heading={"Dotify"} >
            <DotifySingle />
            <DropZone callback={showFileList}/> 
        </InputPanel>
        <OutputPanel heading={"Batch Results"}>
            <BatchResults 
                isFiltered={filtered.length > 0} 
                filter={filterResults} 
                transfer={() => props.transfer(getActiveData(), "remix")}
                clear={clearList} 
                show={{legend: true, controls: true}} 
                fileData={filtered.length ? filtered : fileData} 
            />
        </OutputPanel>
        </>
    );
}