import React from 'react';
// eslint-disable-next-line
import articles from '../utils/data2';
import poster from '../img/imdb.jpg';

export default function SearchBar(props) {
    const [searchValue, setSearchValue] = React.useState('');
    const [isActive, setIsActive] = React.useState(false);
    const [isSearch, setIsSearch] = React.useState(false);

    const containerEl = React.useRef(null);
    const inputEl = React.useRef(null);

    const [isLoading, setIsLoading] = React.useState(true);
    const [error, setError] = React.useState(null);
    const [items, setItems] = React.useState([]);
    const [imdbId, setImdbId] = React.useState(null);

    const options = {
        method: 'GET',
        headers: {
            'X-RapidAPI-Key': process.env.REACT_APP_apiKey,
            'X-RapidAPI-Host': 'imdb8.p.rapidapi.com'
        }
    };

    const handleFocus = (e) => {
        setIsActive(true);
    };

    const handleBlur = (e) => {
        if (e.currentTarget === containerEl.current) {
            return null;
        } else {
            setIsActive(false);
            setIsSearch(false);
            setSearchValue('');
            setImdbId(null);
        }
        
    };

    const handleChange = (e) => {
        setSearchValue(e.target.value);
        setIsActive(true);
        setIsSearch(false);
        setItems([]);
    };

    const handleSubmit = (e) => {
        if (e.key === 'Enter') {
            handleSearch();
        }
    };

    const handleSearch = (e) => {
        if (searchValue === '') return null;
        setIsSearch(true);
        const query = encodeURI(searchValue);
        fetch(`https://imdb8.p.rapidapi.com/title/find?q=${query}`, options)
        .then(response => response.json())
        .then(
            (result) => {
                setIsLoading(false);
                let topHits = [];
                let max = 5;
                try {
                    result.results.forEach( item => {
                        if (!item.titleType || item.titleType === 'videoGame') return null;
                        topHits.push(item)
                    });
                    if (topHits.length < max) setItems(topHits);
                    setItems(topHits.slice(0, max));
                } catch (e) {
                    setError(e);
                }
            },
            (error) => {
                setIsLoading(false);
                setError(error);
            }
        ) 
    };

    const getTitleData = (imdb) => {
        setImdbId(imdb);
        setIsLoading(true);
        setIsSearch(false);
        setIsActive(false);
    };

    return (
        imdbId ? 
        <>
        <ImdbData id={imdbId} /> 
        <div 
            id="container--search" 
            onFocus={handleFocus}
            onBlur={handleBlur}
            ref={containerEl}>

            <div className={isActive ? 'animate-icon-in' : 'animate-icon-out'}>
                <svg id="icon-search" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <path d="M500.3 443.7l-119.7-119.7c27.22-40.41 40.65-90.9 33.46-144.7C401.8 87.79 326.8 13.32 235.2 1.723C99.01-15.51-15.51 99.01 1.724 235.2c11.6 91.64 86.08 166.7 177.6 178.9c53.8 7.189 104.3-6.236 144.7-33.46l119.7 119.7c15.62 15.62 40.95 15.62 56.57 0C515.9 484.7 515.9 459.3 500.3 443.7zM79.1 208c0-70.58 57.42-128 128-128s128 57.42 128 128c0 70.58-57.42 128-128 128S79.1 278.6 79.1 208z"/>
                </svg>
            </div>
        </div>
        <div onClick={handleBlur} className={isActive ? 'hide' : 'blur'}></div>
        </>
        :
        <>
        <div 
            id="container--search" 
            onFocus={handleFocus}
            onBlur={handleBlur}
            ref={containerEl}>

            <div className={`search-results ${isSearch ? 'sr-animate' : ''}`}>
                <SearchItem isSearch={isSearch} isLoading={isLoading} error={error} items={items} callback={getTitleData} />
            </div>
            <div onClick={isActive ? handleSearch : null} className={isActive ? 'animate-icon-in' : 'animate-icon-out'}>
                <svg id="icon-search" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <path d="M500.3 443.7l-119.7-119.7c27.22-40.41 40.65-90.9 33.46-144.7C401.8 87.79 326.8 13.32 235.2 1.723C99.01-15.51-15.51 99.01 1.724 235.2c11.6 91.64 86.08 166.7 177.6 178.9c53.8 7.189 104.3-6.236 144.7-33.46l119.7 119.7c15.62 15.62 40.95 15.62 56.57 0C515.9 484.7 515.9 459.3 500.3 443.7zM79.1 208c0-70.58 57.42-128 128-128s128 57.42 128 128c0 70.58-57.42 128-128 128S79.1 278.6 79.1 208z"/>
                </svg>
            </div>
            <input 
                autoComplete="off"
                type="text"
                id="input--search" 
                className={isSearch ? 'search-active' : (isActive ? 'search-focus' : '')}
                ref={inputEl}
                onChange={handleChange}
                onKeyDown={handleSubmit}
                placeholder="Search"
                value={searchValue}>
            </input>
        </div>
        <div onClick={handleBlur} className={isActive ? 'blur' : 'hide'}></div>
        </>
    );
}

function Spinner(props) {

    return (
        <span className="loading-spinner" style={{...props.style}}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                <path d="M222.7 32.15C227.7 49.08 218.1 66.9 201.1 71.94C121.8 95.55 64 169.1 64 255.1C64 362 149.1 447.1 256 447.1C362 447.1 448 362 448 255.1C448 169.1 390.2 95.55 310.9 71.94C293.9 66.9 284.3 49.08 289.3 32.15C294.4 15.21 312.2 5.562 329.1 10.6C434.9 42.07 512 139.1 512 255.1C512 397.4 397.4 511.1 256 511.1C114.6 511.1 0 397.4 0 255.1C0 139.1 77.15 42.07 182.9 10.6C199.8 5.562 217.6 15.21 222.7 32.15V32.15z"/>
            </svg>
        </span>
    );
}

function SearchItem(props) {
    const renderItem = () => {
        if (props.isLoading || props.items.length === 0) {
            return (
                <Spinner style={{marginBottom: '20px'}}/>
            );
        } else if (props.error) {
            return (
                <div style={{textAlign: 'center', margin: 'auto', marginTop: '10px'}}>Error</div>
            );
        } else {
            return (
                props.items.map( item => {
                    return (
                        <div key={item.id} className="search-item" onClick={() => props.callback(item.id.split('/')[2])} >
                            <Poster src={item.image ? item.image.url : poster}/>
                            <div className="search-item--data">
                                <h2 className="search-item--title">{item.title ? item.title : item.name}</h2>
                                <div className="search-item--meta">
                                    <p className="search-item--type">Type: {item.titleType ? item.titleType : 'unavailable'}</p>
                                    <p className="search-item--year">Year: {item.year ? item.year : 'unavailable'}</p>
                                    <p className="search-item--year">IMDb: {item.id ? item.id.split('/')[2] : item.name}</p>
                                </div>
                            </div>
                        </div>
                    );
                    }
                )
            );
        }
    };

    return (
        renderItem()
    );
}

function PosterHelper(props) {
    const [currentSrc, updateSrc] = React.useState(poster);
    const [loading, setLoading] = React.useState(true);
    
    React.useEffect(() => {
        const imageToLoad = new Image();
        imageToLoad.src = props.src;
        imageToLoad.onload = () => {
          setLoading(false);
          updateSrc(props.src);
        }
    }, [props.src]);
    
    return (
        <img
            src={currentSrc}
            className="search-item--img"
            style={{
                width: "50px",
                height: "75px",
                opacity: loading ? 0.5 : 1,
                transition: "opacity .15s linear"
            }}
            alt="Production Poster."
        />
    );
} const Poster = React.memo(PosterHelper);

function ImdbData(props) {
    const [error, setError] = React.useState(null);
    const [isLoaded, setIsLoaded] = React.useState(false);
    const [results, setResults] = React.useState(null);

    const options = {
        method: 'GET',
        headers: {
            'X-RapidAPI-Key': process.env.REACT_APP_apiKey,
            'X-RapidAPI-Host': 'imdb8.p.rapidapi.com'
        }
    };

    React.useEffect(() => {
        fetch(`https://imdb8.p.rapidapi.com/title/get-full-credits?tconst=${props.id}`, options)
        .then(response => response.json())
        .then(
            (result) => {
                setIsLoaded(true);
                setResults(result);
            },
            (error) => {
                setIsLoaded(true);
                setError(error);
            }
        )
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    if (error) {
        return (
            <div id="imdb-data">
                <h2 style={{width: '100%', textAlign: 'center'}}>Error: {error.message}</h2>
            </div>
        );
    }

    const itemCategory = () => {
        const series = ['tvSeries', 'miniSeries', 'tvEpisode']
        if (series.includes(results.base.titleType)) return 'S - Series';
        return 'F - Not a Series';
    };

    const prodType = () => {
        if (itemCategory() === 'S - Series') return 'SER';
        return 'FIL';
    };

    const imdbPref = () => {
        if (props.id.length === 10) return props.id.substring(1);
        return props.id;
    };

    const imdbDir = () => ( 'director' in results.crew ? results.crew.director[0].legacyNameText : 'Unavailable' );

    return (
        <div id="imdb-data">
        { !isLoaded ? <Spinner style={{marginBottom: '30px'}} /> :
            <div id="pd-general">
                <h2 className="section-title">General</h2>
                <div className="pd-wrapper">
                    <div id="pd-wrapper-left">
                        <div className="pd-label-box" id="pd-title">
                            <span className="pd-label">Title</span>
                            <p className="pd-box-content">{results.base.title ? results.base.title : results.base.name}</p>
                        </div>
                        <div id="pd-sub-wrapper">
                            <div className="pd-label-box" id="pd-category">
                                <span className="pd-label">Category</span>
                                <p className="pd-box-content">{itemCategory()}</p>
                            </div>
                            <div className="pd-label-box" id="pd-eidr">
                                <span className="pd-label">EIDR</span>
                                <p onClick={() => {window.open('https://ui.eidr.org/search')}} className="pd-box-content open-page">Look Up</p>
                            </div>
                        </div>
                    </div>
                    <div id="pd-wrapper-right">
                        <div className="pd-label-box" id="pd-type">
                            <span className="pd-label">Production Type</span>
                            <p className="pd-box-content">{prodType()} - {results.base.titleType}</p>
                        </div>
                        <div className="pd-label-box" id="pd-imdb">
                            <span className="pd-label">IMDb</span>
                            <p onClick={() => {navigator.clipboard.writeText(imdbPref())}} className="pd-box-content copy-content">{props.id}</p>
                        </div>
                    </div>
                </div>
                <div className="hr"></div>
                    <GenExtended id={props.id}/>
                <div className="hr"></div>
                <h2 className="section-title">Participants</h2>
                <div className="pd-wrapper-3">
                    <div className="pd-label-box pd-participant">
                        <span className="pd-label">Participant 1</span>
                        <p className="pd-box-content">{results.cast ? results.cast[0].legacyNameText : 'Unavailable'}</p> 
                    </div> 
                    <div className="pd-label-box pd-capacity">
                        <span className="pd-label">Capacity</span>
                        <p className="pd-box-content">PAC Actor</p> 
                    </div> 
                </div>
                <div className="pd-wrapper-3">
                    <div className="pd-label-box pd-participant">
                        <span className="pd-label">Participant 2</span>
                        <p className="pd-box-content">{results.cast && results.cast[1] ? results.cast[1].legacyNameText : 'Unavailable'}</p> 
                    </div> 
                    <div className="pd-label-box pd-capacity">
                        <span className="pd-label">Capacity</span>
                        <p className="pd-box-content">PAC Actor</p> 
                    </div> 
                </div>
                <div className="pd-wrapper-3">
                    <div className="pd-label-box pd-participant">
                        <span className="pd-label">Participant 3a</span>
                        <p className="pd-box-content">{results.cast && results.cast[2] ? results.cast[2].legacyNameText : 'Unavailable'}</p> 
                    </div> 
                    <div className="pd-label-box pd-capacity">
                        <span className="pd-label">Capacity</span>
                        <p className="pd-box-content">PAC Actor</p> 
                    </div> 
                </div>
                <div className="pd-wrapper-3">
                    <div className="pd-label-box pd-participant">
                        <span className="pd-label">Participant 3b</span>
                        <p className="pd-box-content">{imdbDir()}</p> 
                    </div> 
                    <div className="pd-label-box pd-capacity">
                        <span className="pd-label">Capacity</span>
                        <p className="pd-box-content">DIR Director</p> 
                    </div> 
                </div>
                <div className="hr"></div>
                <h2 className="section-title">Alternate Titles</h2>
                <AltTitles id={props.id} imdbTitle={results.base.title} />
            </div>
        }
        </div>
    );
}

function GenExtended(props) {
    const [results, setResults] = React.useState(null);
    const [isLoaded, setIsLoaded] = React.useState(false);
    const [error, setError] = React.useState(null);

    const options = {
        method: 'GET',
        headers: {
            'X-RapidAPI-Key': process.env.REACT_APP_apiKey,
            'X-RapidAPI-Host': 'mdblist.p.rapidapi.com'
        }
    };

    React.useEffect(() => {
        fetch(`https://mdblist.p.rapidapi.com/?i=${props.id}`, options)
        .then(response => response.json())
        .then(
            (result) => {
                setIsLoaded(true);
                setResults(result);
            },
            (error) => {
                setIsLoaded(true);
                setError(error);
            }
        )
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    if (error) {
        setResults({
            country: 'us',
            language: 'en',
            year: 'Network Error'
        });
    }

    const countryCode = () => {
        if (results.country) {
            if (results.country === 'us') return '0840 US';
            else if (results.country === 'gb') return '0826 GB';
            else if (results.country === 'fr') return '0250 FR';
            else return `#### ${results.country.toUpperCase()}`;
        }
        return '0840 US';
    };

    return (
        <>
            { !isLoaded ? <Spinner style={{marginBottom: '30px'}} /> :
                <div className="pd-wrapper-2">
                    <div className="pd-label-box" id="pd-language">
                        <span className="pd-label">Language</span>
                        <p className="pd-box-content">{results.language ? results.language.toUpperCase() : 'EN'}</p>
                    </div>
                    <div className="pd-label-box" id="pd-year">
                        <span className="pd-label">Production Year</span>
                        <p className="pd-box-content">{results.year ? results.year : 'Unavailable'}</p> 
                    </div>
                    <div className="pd-label-box" id="pd-territory">
                        <span className="pd-label">Circulation Territory</span>
                        <p className="pd-box-content">001 - World</p>
                    </div>
                    <div className="pd-label-box" id="pd-country">
                        <span className="pd-label">Origin Country</span>
                        <p className="pd-box-content">{countryCode()}</p> 
                    </div>
                    <div className="pd-label-box" id="pd-company">
                        <span className="pd-label">Production Company</span>
                        <p onClick={() => {window.open(`https://www.imdb.com/title/${props.id}/companycredits`)}} className="pd-box-content open-page">View List</p> 
                    </div>
                    <div className="pd-label-box" id="pd-territory">
                        <span className="pd-label">AVI Number</span>
                        <p className="pd-box-content">AVI</p>
                    </div>
                    <div className="pd-label-box" id="pd-property">
                        <span className="pd-label">Intellectual Property ID</span>
                        <p className="pd-box-content">Property ID</p> 
                    </div>
                </div>
            }
        </>
    );
}

function AltTitles(props) {
    const [error, setError] = React.useState(null);
    const [isLoaded, setIsLoaded] = React.useState(false);
    const [results, setResults] = React.useState([]);
    const [isDetected, setIsDetected] = React.useState(false);
    const [langData, setLangData] = React.useState([]);
    const ignoreLang = ['HAW', 'AR', 'BG', 'ZH-TW', 'ZH-CN', 'UK', 'RU', 'BG', 'UK', 'MK', 'SR', 'JA', 'KO', 'HI', 'IW', 'MN', 'BE', 'HE', 'HI', 'KA'];
    const titleList = new Set();

    const options = {
        method: 'GET',
        headers: {
            'X-RapidAPI-Key': process.env.REACT_APP_apiKey,
            'X-RapidAPI-Host': 'imdb8.p.rapidapi.com'
        }
    };

    function remove_duplicates(current) {
        let filtered = current.map((current) => current.title).filter((current) => {
        let currentTitle = current.toLowerCase();
        if (currentTitle === props.imdbTitle.toLowerCase()) return false;
            if (!titleList.has(currentTitle)) {
                titleList.add(currentTitle);
                return true;
            }
            return false;
        });
        return filtered
    }

    function separateArticle(data) {
        let seperated = data.map((current) => {
            if (!articles[current.lang_code]) {
                return { 
                    "article": "", 
                    "title": current.decoded_title, 
                    "langCode": current.lang_code };
            }
        
            let lowerCaseString = current.decoded_title.toLowerCase();
      
            for (let article of articles[current.lang_code]) {
                if (lowerCaseString.startsWith(article + " ")) {
                    return { 
                        "article": article, 
                        "title": current.decoded_title.slice(article.length).trim(), 
                        "langCode": current.lang_code };
                }
            }
      
            return { "article": "", "title": current.decoded_title, "langCode": current.lang_code };
        });
        return seperated        
    }

    function altPref(item) {
        const titleType = item.langCode === 'en' ? 'AT' : 'TT'
        return `${item.title}\t${item.article}\t${titleType}\t${item.langCode}`
    }

    React.useEffect(() => {
        fetch(`https://imdb8.p.rapidapi.com/title/get-versions?tconst=${props.id}`, options)
	    .then(response => response.json())
	    .then(
            (result) => {
                setIsLoaded(true);
                if (result.alternateTitles) {
                    setResults(remove_duplicates(result.alternateTitles));
                } 
                else {
                    setError('No Alternate Titles.');
                }
            }, 
            (error) => {
                setError(error);
            });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
        if (results.length !== 0) {
                fetch(`https://lang-detect-5lct.onrender.com/api/detect`, {
                    method: 'POST', 
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(results)
            })
            .then(response => response.json())
            .then( result => {
                    setIsDetected(true);
                    setLangData(separateArticle(result.data));
                })
            .catch( error => {
                    setIsDetected(true);
                    setError(error);
                }
            );
        }
    }, [results]); // eslint-disable-line react-hooks/exhaustive-deps
    
    if (error) {
        return (
            <div className="pd-wrapper-4">
                <div className="title-error">{error}</div>
            </div>
        );
    } else if (!isLoaded) {
        return (
            <Spinner />
        );
    } else if (results.length === 0) {
        return (
            <div className="pd-wrapper-4">
                <div className="title-error">No Alternate Titles.</div>
            </div>
        );
    } else if (!isDetected) {
        return (
            <div className="pd-wrapper-4">
                <div className="pd-label-box pd-alt-titles">
                    <span className="pd-label">Title</span>
                    <p className="pd-box-content">Transliterating title. . .</p> 
                </div>
                <div className="pd-label-box pd-title-article">
                    <span className="pd-label">Article</span>
                    <p className="pd-box-content">
                    Detecting. . .
                    </p> 
                </div>  
                <div className="pd-label-box pd-title-type">
                    <span className="pd-label">AKA Type</span>
                    <p className="pd-box-content">
                    . . .
                    </p> 
                </div> 
                <div className="pd-label-box pd-title-lang">
                    <span className="pd-label">Language</span>
                    <p className='pd-box-content'>
                        . . .
                    </p>
                </div>  
            </div>
        );
    } else {
        return (
            <>
            {langData.filter((item) => !ignoreLang.includes(item.langCode.toUpperCase())).map( (item, index) => {
                return (
                    <div key={item.itle+String(index)} className="pd-wrapper-4 copy-content" onClick={() => navigator.clipboard.writeText(altPref(item))}>
                        <div className="pd-label-box pd-alt-titles">
                            <span className="pd-label">Title</span>
                            <p className="pd-box-content">{item.title.toUpperCase()}</p> 
                        </div> 
                        <div className="pd-label-box pd-title-article">
                            <span className="pd-label">Article</span>
                            <p className="pd-box-content">
                            {item.article.toUpperCase()}
                            </p> 
                        </div> 
                        <div className="pd-label-box pd-title-type">
                            <span className="pd-label">AKA Type</span>
                            <p className="pd-box-content">
                            {item.langCode === 'en' ? 'AT' : 'TT'}
                            </p> 
                        </div> 
                        <div className="pd-label-box pd-title-lang">
                            <span className="pd-label">Language</span>
                            <p className='pd-box-content'>
                                {item.langCode.toUpperCase()}
                            </p>
                        </div>  
                    </div>
                );
            })}
            </>
        );
    }

}