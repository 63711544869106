import React from 'react';
import { FaAdjust } from 'react-icons/fa';
import SearchBar from './SearchBar';

export default function MainHeader(props) {
    const version = process.env.REACT_APP_version;
    const [theme, setTheme] = React.useState(
        localStorage.getItem('theme') || 'light'
    );

    const changeMode = () => { 
        document.body.classList.add('toggle'); // transition only on toggle, not page load
        if (theme === 'light') {
            setTheme('dark');
            document.body.classList.remove('light');
            document.body.classList.add('dark');
            localStorage.setItem('theme', 'dark');
        } else {
            setTheme('light');
            document.body.classList.remove('dark');
            document.body.classList.add('light');
            localStorage.setItem('theme', 'light');
        }
    }

    React.useLayoutEffect(() => {
        document.body.classList.add(theme);
        // eslint-disable-next-line
    },[]);

    return (
        <header>
            <div id="wrapper-header">
                <FaAdjust id="mode-toggle" onClick={changeMode} />
                <SearchBar />
                <div id={theme === 'light' ? 'logo' : 'logo-dark'}><p id="version">{version}</p></div>
            </div>
        </header>
    );
}
