import React from 'react';
import Decimal from 'decimal.js';

export default function Calculon(props) {

    return (
        <>
        <h2>Calculon</h2> 
        <div className="calculon-modules-container">
            <Modulate />
            <Diff />
            <ControlledScore />
            <Add />
        </div>
        </>
    );
}

function ControlledScore(props) {
    const [isActive, setIsActive] = React.useState(false);
    const [controlled, setControlled] = React.useState('HH:MM:SS');
    const [state, setState] = React.useState({
        h1: '', h2: '', h3: '',
        m1: '', m2: '', m3: '',
        s1: '', s2: '', s3: ''
    });

    const handleChange = (e) => {
        setIsActive(true);
        const value = e.target.value;
        setState({
            ...state,
            [e.target.name]: value
        });
    };

    const clearInput = () => {
        setIsActive(false);
        setState({
            h1: '', h2: '', h3: '',
            m1: '', m2: '', m3: '',
            s1: '', s2: '', s3: ''
        }); 
    };

    React.useEffect(() => {
        if ((state.h1 === '' && state.m1 === '' && state.s1 === '') ||
            (state.h2 === '' && state.m2 === '' && state.s2 === '') ||
            (state.h3 === '' && state.m3 === '' && state.s3 === '')) {
                setControlled('HH:MM:SS');
        } else {
            let totalDuration = Number(state.h1) * 60 * 60 + Number(state.h2) * 60 * 60 + Number(state.h3) * 60 * 60 +
                                Number(state.m1) * 60 + Number(state.m2) * 60 + Number(state.m3) * 60 +
                                Number(state.s1) + Number(state.s2) + Number(state.s3);
            let avgDuration = new Decimal(totalDuration).dividedBy(3);
            let avgHour = new Decimal(avgDuration).dividedBy(3600);
            let avgMin = new Decimal(avgHour).mod(1).times(60);
            let avgSec = new Decimal(avgMin).mod(1).times(60);
            setControlled(`${String(Math.floor(avgHour)).padStart(2,'0')}:${String(Math.floor(avgMin)).padStart(2, '0')}:${String(Math.floor(avgSec)).padStart(2, '0')}`);
        }
    }, [state]);  

    
    return (
        <div className="calculon-module" id="controlled-score-container">
            <p className="main-container-subheader">TV Controlled Score</p>
            <div className="calculon-component" id="controlled-score">
                <svg onClick={clearInput} className={`calculon-clear ${isActive ? 'calculon-clear-hide' : ''}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                    <path d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z"/>
                </svg>
                <div id="controlled-score-result">
                    <h2>Controlled Score</h2>
                    <div className="score-output">{controlled}</div>
                </div>
                <div className="input-wrapper">
                    <div>
                        <span className="input-labels">Ep No. 1</span>
                        <input onChange={handleChange} value={state.h1} name="h1" min={0} max={2} className="calculon-input" id="hours-one" type="number" placeholder="HH" />
                        <input onChange={handleChange} value={state.m1} name="m1" min={0} max={59} className="calculon-input" id="minutes-one" type="number" placeholder="MM" />
                        <input onChange={handleChange} value={state.s1} name="s1" min={0} max={59} className="calculon-input" id="seconds-one" type="number" placeholder="SS" />
                    </div>
                    <div>
                        <span className="input-labels">Ep No. 2</span>
                        <input onChange={handleChange} value={state.h2} name="h2" min={0} max={2} className="calculon-input" id="hours-two" type="number" placeholder="HH" />
                        <input onChange={handleChange} value={state.m2} name="m2" min={0} max={59} className="calculon-input" id="minutes-two" type="number" placeholder="MM" />
                        <input onChange={handleChange} value={state.s2} name="s2" min={0} max={59} className="calculon-input" id="seconds-two" type="number" placeholder="SS" />
                    </div>
                    <div>
                        <span className="input-labels">Ep No. 3</span>
                        <input onChange={handleChange} value={state.h3} name="h3" min={0} max={2} className="calculon-input" id="hours-three" type="number" placeholder="HH" />
                        <input onChange={handleChange} value={state.m3} name="m3" min={0} max={59} className="calculon-input" id="minutes-three" type="number" placeholder="MM" />
                        <input onChange={handleChange} value={state.s3} name="s3" min={0} max={59} className="calculon-input" id="seconds-three" type="number" placeholder="SS" />
                    </div>
                </div>
            </div>
        </div>
    );
}

function Modulate(props) {
    const [isActive, setIsActive] = React.useState(false);
    const [standardized, setStandardized] = React.useState('HH:MM:SS');
    const [state, setState] = React.useState({
        min: '',
        sec: ''
    });

    const handleChange = (e) => {
        setIsActive(true);
        const value = e.target.value;
        setState({
            ...state,
            [e.target.name]: value
        });
    };

    const clearInput = () => {
        setIsActive(false);
        setState({
            min: '',
            sec: ''
        });
    };

    React.useEffect(() => {
        if (state.min === 0 || state.min === '') {
            setStandardized('HH:MM:SS');
        } else {
            setIsActive(true);
            setStandardized(
                `${String(Math.floor(state.min / 60)).padStart(2, '0')}:
                ${String(state.min % 60).padStart(2, '0')}:
                ${String(state.sec || '0').padStart(2, '0')}`);
        }
    }, [state]);

    return (
        <div className="calculon-module">
        <p className="main-container-subheader">Modulation</p>
        <div className="calculon-component" id="modulate">
            <svg onClick={clearInput} className={`calculon-clear ${isActive ? 'calculon-clear-hide' : ''}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                <path d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z"/>
            </svg>
            <div id="modulate-result">
                <h2>Standardized</h2>
                <div>{standardized}</div>
            </div>
            <div className="input-wrapper">
            <div>
                <span className="input-labels">Duration</span>
                <input 
                    className="calculon-input" 
                    id="mod-minutes" 
                    type="number" 
                    placeholder="MM"
                    name="min"
                    min={0}
                    max={1440}
                    value={state.min}
                    onChange={handleChange} />
                <input 
                    className="calculon-input" 
                    id="mod-seconds" 
                    type="number" 
                    placeholder="SS"
                    name="sec"
                    min={0}
                    max={60}
                    value={state.sec}
                    onChange={handleChange} />
            </div>
            </div>
        </div>
    </div> 
    );
}

function Diff(props) {
    const [difference, setDifference] = React.useState('HH:MM:SS');
    const [isActive, setIsActive] = React.useState(false);
    //TODO: convert to state
    const hourStart = React.useRef(null);
    const minuteStart = React.useRef(null);
    const secondStart = React.useRef(null);
    const hourStop = React.useRef(null);
    const minuteStop = React.useRef(null);
    const secondStop = React.useRef(null);

    const subtract = () => {
        if ((hourStart.current.value === '' && minuteStart.current.value === '' && secondStart.current.value === '') ||
            (hourStop.current.value === ''  && minuteStop.current.value === '' && secondStop.current.value === '')) {
             setIsActive(false);
             setDifference('HH:MM:SS');
        }
        else {
            setIsActive(true);
            let diffHours = hourStop.current.value - hourStart.current.value;
            let diffMins = minuteStop.current.value - minuteStart.current.value;
            let diffSecs = secondStop.current.value - secondStart.current.value;

            if (diffSecs < 0) { 
                diffMins -= 1; 
                diffSecs = 60 + diffSecs
            }
            if (diffMins < 0) {
                diffHours -= 1;
                diffMins = 60 + diffMins;
            }
            if (diffHours < 0) {
                setDifference('HH:MM:SS')
            } else {
                setDifference(`${String(diffHours).padStart(2,'0')}:${String(diffMins).padStart(2,'0')}:${String(diffSecs).padStart(2,'0')}`);
            }
        }
        
    };

    const clearInput = () => {
        hourStart.current.value = hourStop.current.value = minuteStart.current.value = minuteStop.current.value = secondStart.current.value = secondStop.current.value = null;
        setIsActive(false);
        setDifference('HH:MM:SS');
    };

    return (
        <div className="calculon-module">
        <p className="main-container-subheader">Subtraction</p>
        <div className="calculon-component" id="diff">
            <svg onClick={clearInput} className={`calculon-clear ${isActive ? 'calculon-clear-hide' : ''}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                <path d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z"/>
            </svg>
            <div id="diff-result">
                <h2>Difference</h2>
                <div>{difference}</div>
            </div>
            <div className="input-wrapper">
                <div className="input-wrapper-top">
                    <span className="input-labels">Start</span>
                    <input className="calculon-input" 
                           id="hours-start" 
                           type="number"  
                           placeholder="HH"
                           min={0}
                           max={23}
                           ref={hourStart}
                           onChange={subtract} />
                    <input className="calculon-input"
                           id="minutes-start" 
                           type="number"  
                           placeholder="MM"
                           min={0}
                           max={59}
                           ref={minuteStart}
                           onChange={subtract} />
                    <input className="calculon-input" 
                           id="seconds-start" 
                           type="number"  
                           placeholder="SS"
                           min={0}
                           max={59}
                           ref={secondStart}
                           onChange={subtract} />
                </div>
                <div className="input-wrapper-bottom">
                    <span className="input-labels">Stop</span>
                    <input className="calculon-input" 
                           id="hours-stop" 
                           type="number" 
                           placeholder="HH"
                           min={0}
                           max={23}
                           ref={hourStop}
                           onChange={subtract} />
                    <input className="calculon-input" 
                           id="minutes-stop" 
                           type="number"  
                           placeholder="MM"
                           min={0}
                           max={59}
                           ref={minuteStop}
                           onChange={subtract} />
                    <input className="calculon-input" 
                           id="seconds-stop" 
                           type="number"  
                           placeholder="SS"
                           min={0}
                           max={59}
                           ref={secondStop}
                           onChange={subtract} />
                </div>
            </div>
        </div>
    </div> 
    );
}

function Add(props) {
    const [isActive, setIsActive] = React.useState(false);
    const [total, setTotal] = React.useState('HH:MM:SS');
    const [state, setState] = React.useState({
        h1: '', h2: '', h3: '', h4: '', h5: '', h6: '', h7: '', h8: '',
        m1: '', m2: '', m3: '', m4: '', m5: '', m6: '', m7: '', m8: '',
        s1: '', s2: '', s3: '', s4: '', s5: '', s6: '', s7: '', s8: ''
    });

    const handleChange = (e) => {
        setIsActive(true);
        const value = e.target.value;
        setState({
            ...state,
            [e.target.name]: value
        });
    };

    const clearInput = () => {
        setIsActive(false);
        setState({
            h1: '', h2: '', h3: '', h4: '', h5: '', h6: '', h7: '', h8: '',
            m1: '', m2: '', m3: '', m4: '', m5: '', m6: '', m7: '', m8: '',
            s1: '', s2: '', s3: '', s4: '', s5: '', s6: '', s7: '', s8: ''
        }); 
    };

    React.useEffect(() => {
        if ((state.h1 === '' && state.m1 === '' && state.s1 === '') &&
            (state.h2 === '' && state.m2 === '' && state.s2 === '') &&
            (state.h3 === '' && state.m3 === '' && state.s3 === '') &&
            (state.h4 === '' && state.m4 === '' && state.s4 === '') &&
            (state.h5 === '' && state.m5 === '' && state.s5 === '') &&
            (state.h6 === '' && state.m6 === '' && state.s6 === '') &&
            (state.h7 === '' && state.m7 === '' && state.s7 === '') &&
            (state.h6 === '' && state.m8 === '' && state.s8 === '')) {
                setTotal('HH:MM:SS');
        } else {
            let totalDuration = Number(state.h1) * 60 * 60 + Number(state.h2) * 60 * 60 + Number(state.h3) * 60 * 60 + Number(state.h4) * 60 * 60 +
                                Number(state.h5) * 60 * 60 + Number(state.h6) * 60 * 60 + Number(state.h7) * 60 * 60 + Number(state.h8) * 60 * 60 +
                                Number(state.m1) * 60 + Number(state.m2) * 60 + Number(state.m3) * 60 + Number(state.m4) * 60 +
                                Number(state.m5) * 60 + Number(state.m6) * 60 + Number(state.m7) * 60 + Number(state.m8) * 60 +
                                Number(state.s1) + Number(state.s2) + Number(state.s3) + Number(state.s4) +
                                Number(state.s5) + Number(state.s6) + Number(state.s7) + Number(state.s8);
            let totalHour = new Decimal(totalDuration).dividedBy(3600);
            let totalMin = new Decimal(totalHour.mod(1)).times(60);
            let totalSec = new Decimal(totalMin.mod(1)).times(60).toPrecision(10);
            setTotal(`${String(Math.floor(totalHour)).padStart(2,'0')}:${String(Math.floor(totalMin)).padStart(2, '0')}:${String(Math.floor(totalSec)).padStart(2, '0')}`);
        }
    }, [state]);  

    return (
        <div className="calculon-module">
        <p className="main-container-subheader">Addition</p>
        <div className="calculon-component" id="add">
            <svg onClick={clearInput} className={`calculon-clear ${isActive ? 'calculon-clear-hide' : ''}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                <path d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z"/>
            </svg>
            <div id="add-result">
                <h2>Total Time</h2>
                <div>{total}</div>
            </div>
            <div className="input-wrapper">
                <div>
                    <span className="input-labels">Time 1</span>
                    <input onChange={handleChange} name="h1" value={state.h1} min={0} max={2} className="calculon-input" id="h-time-1" type="number" placeholder="HH" />
                    <input onChange={handleChange} name="m1" value={state.m1} className="calculon-input" id="m-time-1" type="number" placeholder="MM" />
                    <input onChange={handleChange} name="s1" value={state.s1} className="calculon-input" id="s-time-1" type="number" placeholder="SS" />
                </div>
                <div>
                    <span className="input-labels">Time 2</span>
                    <input onChange={handleChange} name="h2" value={state.h2} min={0} max={2} className="calculon-input" id="h-time-2" type="number" placeholder="HH" />
                    <input onChange={handleChange} name="m2" value={state.m2} className="calculon-input" id="m-time-2" type="number" placeholder="MM" />
                    <input onChange={handleChange} name="s2" value={state.s2} className="calculon-input" id="s-time-2" type="number" placeholder="SS" />
                </div>
                <div>
                    <span className="input-labels">Time 3</span>
                    <input onChange={handleChange} name="h3" value={state.h3} min={0} max={2} className="calculon-input" id="h-time-3" type="number" placeholder="HH" />
                    <input onChange={handleChange} name="m3" value={state.m3} className="calculon-input" id="m-time-3" type="number" placeholder="MM" />
                    <input onChange={handleChange} name="s3" value={state.s3} className="calculon-input" id="s-time-3" type="number" placeholder="SS" />
                </div>
                <div>
                    <span className="input-labels">Time 4</span>
                    <input onChange={handleChange} name="h4" value={state.h4} min={0} max={2} className="calculon-input" id="h-time-4" type="number" placeholder="HH" />
                    <input onChange={handleChange} name="m4" value={state.m4} className="calculon-input" id="m-time-4" type="number" placeholder="MM" />
                    <input onChange={handleChange} name="s4" value={state.s4} className="calculon-input" id="s-time-4" type="number" placeholder="SS" />
                </div>
                <div>
                    <span className="input-labels">Time 5</span>
                    <input onChange={handleChange} name="h5" value={state.h5} min={0} max={2} className="calculon-input" id="h-time-5" type="number" placeholder="HH" />
                    <input onChange={handleChange} name="m5" value={state.m5} className="calculon-input" id="m-time-5" type="number" placeholder="MM" />
                    <input onChange={handleChange} name="s5" value={state.s5} className="calculon-input" id="s-time-5" type="number" placeholder="SS" />
                </div>
                <div>
                    <span className="input-labels">Time 6</span>
                    <input onChange={handleChange} name="h6" value={state.h6} min={0} max={2} className="calculon-input" id="h-time-6" type="number" placeholder="HH" />
                    <input onChange={handleChange} name="m6" value={state.m6} className="calculon-input" id="m-time-6" type="number" placeholder="MM" />
                    <input onChange={handleChange} name="s6" value={state.s6} className="calculon-input" id="s-time-6" type="number" placeholder="SS" />
                </div>
                <div>
                    <span className="input-labels">Time 7</span>
                    <input onChange={handleChange} name="h7" value={state.h7} min={0} max={2} className="calculon-input" id="h-time-7" type="number" placeholder="HH" />
                    <input onChange={handleChange} name="m7" value={state.m7} className="calculon-input" id="m-time-7" type="number" placeholder="MM" />
                    <input onChange={handleChange} name="s7" value={state.s7} className="calculon-input" id="s-time-7" type="number" placeholder="SS" />
                </div>
                <div>
                    <span className="input-labels">Time 8</span>
                    <input onChange={handleChange} name="h8" value={state.h8} min={0} max={2} className="calculon-input" id="h-time-8" type="number" placeholder="HH" />
                    <input onChange={handleChange} name="m8" value={state.m8} className="calculon-input" id="m-time-8" type="number" placeholder="MM" />
                    <input onChange={handleChange} name="s8" value={state.s8} className="calculon-input" id="s-time-8" type="number" placeholder="SS" />
                </div>
            </div>
        </div>
    </div> 
    );
}