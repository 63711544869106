import React, {useState} from 'react';
import MainHeader from './components/MainHeader';
import Controller from './components/Controller';
import Dotify from './frames/Dotify';
import Calculon from './frames/Calculon';
import Remix from './frames/Remix';
import Changelog from './frames/Changelog';

function App() {
  const date = new Date();
  const title = 'TulBOX';
  const [activeFrame, setActiveFrame] = useState('dotify');
  const [fileData, setFileData] = useState([]);
  const resetFrame = () => setFileData([]);
  const switchFrame = (frame) => {
    setFileData([]);
    setActiveFrame(frame);
  };

  function transferData(fileData, frame) {
    setFileData(fileData);
    setActiveFrame(frame);
  }


  return (
    <>
    <MainHeader title={title} />
    <div id="wrapper">
      <Controller activeFrame={activeFrame} switchFrame={switchFrame} />
      <div id='main-container'>
        {{
          dotify: <Dotify reset={resetFrame} transfer={transferData} transferredData={fileData} />,
          remix: <Remix reset={resetFrame} transfer={transferData} transferredData={fileData} />,
          calculon: <Calculon />,
          isrc: <Changelog />
        }[activeFrame]}
      </div>
    </div>
    <footer><p id="copyright">Copyright &copy; 2022 - {date.getFullYear()} Brandon Johnson. All Rights Reserved.</p></footer>
    </>
  );
}

export default App;
