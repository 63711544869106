import React from 'react';

export default function BatchItem({ fileData }) {
    const handleCopy = (value) => navigator.clipboard.writeText(value);

    const placeHolder = (
        <div id="batch-placeholder">
            <h3 id="batch-placeholder-title">
                Processed Files Appear Here
            </h3>
            <svg id="batch-placeholder-icon" xmlns="http://www.w3.org/2000/svg"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z" />
            </svg>
        </div>
    );

    const listItems = fileData.map( (file, index) => {
        return (
            <div key={index.toString()} className="dotify-batch-item">
                <span 
                    className={`batch-flag flag-${file.status}`}>
                </span>
                <h3 className="batch-item-title">
                    {file.name}
                </h3>
                <div className="item-icons">
                    <span className="item-char-count">Ct: {file.name.length}</span>
                    <svg onClick={() => handleCopy(file.name)} className="controller--icon icon-copy" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                        <path d="M320 64h-49.61C262.1 27.48 230.7 0 192 0S121 27.48 113.6 64H64C28.65 64 0 92.66 0 128v320c0 35.34 28.65 64 64 64h256c35.35 0 64-28.66 64-64V128C384 92.66 355.3 64 320 64zM192 48c13.23 0 24 10.77 24 24S205.2 96 192 96S168 85.23 168 72S178.8 48 192 48zM336 448c0 8.82-7.178 16-16 16H64c-8.822 0-16-7.18-16-16V128c0-8.82 7.178-16 16-16h18.26C80.93 117.1 80 122.4 80 128v16C80 152.8 87.16 160 96 160h192c8.836 0 16-7.164 16-16V128c0-5.559-.9316-10.86-2.264-16H320c8.822 0 16 7.18 16 16V448z"/>
                    </svg>
                </div>
            </div>
        );
    });


    return ( fileData.length > 0 ? listItems : placeHolder );
};
