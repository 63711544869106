import React from 'react';

export default function DotifySingle(props) {
    const [value, setValue] =  React.useState('');
    const [valueLength, setValueLength] = React.useState(0);
    const [isCopied, setisCopied] = React.useState(false);
    const [isChecked, setIsChecked] = React.useState(false);
    const [isError, setIsError] = React.useState(false);
    const [isDotified, setIsDotified] = React.useState(false);
    const valueEl = React.useRef(null);

    const toggleMode = (e) => {
        setIsChecked((prevState) => !prevState);
        if (isError) setIsError(false);         // switching to film after receiving error
    };

    const handleSubmit = (e) => {
        if (e.key === 'Enter') {
            dotifyTitle(value);
        }
    };

    const handleChange = (event) => {
        if (typeof event === 'string') {
            setValue(event);
            setValueLength(event.length);
            setIsDotified(true);
        } else {
            if (isCopied) setisCopied(false);  // if clearing without button click
            if (isError) setIsError(false);
            setValue(event.target.value);
            setValueLength(event.target.value.length);
        }

    };

    const handleFocus = (event) => {
        if (isDotified) {
            handleReset();
        }
    };

    const handleReset = () => {
        setValue('');
        setValueLength(0);
        setIsError(false);
        setisCopied(false);
        setIsDotified(false);
    };

    const dotifyTitle = (title) => {
        if (title.length < 60) return null; // if enter key pressed

        const posFileExtension = title.search(/(\.\b[^.]*)$/);
        let cleanTitle = null;
        if (posFileExtension === -1) cleanTitle = title;
        else cleanTitle = title.substring(0, posFileExtension).trim();

        if (isChecked) {    // films
            let prodTitle = cleanTitle;
            let stopCount = 55;
            while (prodTitle.length > stopCount) prodTitle = prodTitle.substring(0, prodTitle.length - 1);
            prodTitle += '. . .';

            navigator.clipboard.writeText(prodTitle);
            setIsError(false);
            setisCopied(true);
            handleChange(prodTitle);
            
        } else {            // series
            const posTitle = cleanTitle.search(/\b\s\s\s\b/);
            const posEpName = cleanTitle.search(/(\b|\?|!)\s\s\b/); 

            if (posTitle === -1 || posEpName === -1) setIsError(true);
            else {
                let prodTitle = cleanTitle.substring(0, posTitle).trim();
                let epTitle = cleanTitle.substring(posTitle, posEpName).trim();
                let epNumber = cleanTitle.substring(posEpName + 1).trim(); // move right 1 to include special char
                console.log(epNumber);

                let dotifyEpTitle = false;
                let dotifyProdTitle = false;

                let stopCount = 50;
                while ((prodTitle.length + epTitle.length + epNumber.length) > stopCount) {
                    if (epTitle.length > 3) {
                        epTitle = epTitle.substring(0, epTitle.length - 1);
                        dotifyEpTitle = true;
                    } else {
                        prodTitle = prodTitle.substring(0, prodTitle.length - 1);
                        dotifyProdTitle = true;
                        stopCount = 45;
                    }
                }

                epTitle = epTitle.trim();   // if shortened string ends with space char
                prodTitle = prodTitle.trim();
            
                if (dotifyEpTitle) epTitle += '. . .';
                if (dotifyProdTitle) prodTitle += '. . .';


                let newTitle = `${prodTitle}   ${epTitle}  ${epNumber}`;
                navigator.clipboard.writeText(newTitle);
                setIsError(false);
                setisCopied(true);
                handleChange(newTitle);
            }
        }
    };

    return (
        <>
        <div id="dotify-single">
            <p className="main-container-subheader">Manual</p>
            <div className="component" id="dotify-single-box">
            <svg onClick={handleReset} className={`dotify-clear ${valueLength > 0 ? 'dotify-clear-hide' : ''}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                <path d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z"/>
            </svg>
                <input 
                    autoFocus
                    type="text"
                    autoComplete="off"
                    onFocus={handleFocus} 
                    id="dotify-single-input" 
                    ref={valueEl} 
                    onKeyDown={handleSubmit}
                    onChange={handleChange}
                    value={value}>
                </input>
                <div id="dotify-button-container">
                    <div id="dotify-char-count">
                        <p className="main-container-subheader">Count: <span className={valueLength > 60 ? 'invalid' : 'valid'}>{valueLength}</span></p>
                    </div>
                    <div id="toggle-type">
                        <span id="toggle-value">{isChecked ? 'Film' : 'Series'}</span>
                        <div id="toggle-background">
                            <span 
                                className={isChecked ? 'toggle-checked' : ''} 
                                id="toggle-foreground"
                                onClick={toggleMode}></span>
                        </div>
                    </div>
                    <button 
                    disabled={valueLength > 60 ? false : true}
                    className="button-dotify"
                    onClick={() => dotifyTitle(value)}>Dotify</button>
                </div>
                <Flash isError={isError} isCopied={isCopied}/>
            </div>
        </div>
        </>
    );
}


function Flash(props) {
    function detectFlash() {
        if (props.isError) {
            return (
                <div 
                    className="flash flash-error">
                    Invalid Format
                </div>
            );
        } else if (props.isCopied) {
            return (
                <div 
                    className="flash flash-copy">
                    Copied to Clipboard
                </div> 
            );
        }
        return '';
    }

    return (
        <>
            {detectFlash()}
        </>
    );
}

// function Reset(props) {

//     return (

//     );
// }