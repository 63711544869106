import React from 'react';
import { titleCase } from '../utils/helpers';

export default function BatchLegend(props) {
    const legend = () => {
        if (props.counts) {
            const legendLabels = Object.keys(props.counts);
            const clickHandler = legendLabels.length > 1 ? props.filter : (() => null);
            return Object.keys(props.counts).map( label => (
                <div key={label} onClick={() => clickHandler(label)} className="legend-item">
                    <span className="legend-item-flag" id={`label-${label}`}></span>
                    <p className="main-container-subheader legend-item-label"><span>{titleCase(label)}:</span> {props.counts[label]}</p>
                </div> 
            ));
        }
    };

    return (
        <div className="batch-results-legend">
        {legend()}
        {props.isFiltered ? 
        (
            <div onClick={() => props.filter('clear')} className="legend-item legend-clear-filter">
                <p className="main-container-subheader legend-item-label">Clear Filter</p>
            </div>
        )
        :
        null}
    </div>
    );
}