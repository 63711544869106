function getStatusCount(fileData) {
    const statusList = [...new Set(fileData.map( file => file.status))];
    const statusCount = statusList.reduce( (accumulator, status) => {
        return {...accumulator, [status]: 0};
    }, {});
    fileData.forEach( file => statusCount[file.status] += 1);
    return statusCount;
}

function titleCase(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export {getStatusCount, titleCase};