import React from 'react';
import v200 from '../img/v200.png'
import v141 from '../img/v141.png'

export default function Changelog(props) {

    return (
        <>
        <h2>Changelog</h2> 
        <p className="main-container-subheader">The latest updates and changes to tulbox.</p>
        <div className="change-container">
            <div className="change-row">
                <div className="change-col-left">
                    <p className="change-date">July 11, 2024</p>
                    <p className="change-title">Style Restructure</p>
                    <p className="change-version">v2.1.0</p>
                </div>
                <div className="change-col-right">
                    <p className="change-description">
                    This is simply a minor restructuring to update and include licensing details
                    for the new API. It also makes this changelog visible to the end user.
                    </p>
                </div>
            </div>
            <div className="change-row">
                <div className="change-col-left">
                    <p className="change-date">June 25, 2024</p>
                    <p className="change-title">AKA Transliteration</p>
                    <p className="change-version">v2.0.0</p>
                </div>
                <div className="change-col-right">
                    <img className="changelog-img" src={v200} alt="Screenshot of update." />
                    <p className="change-description">
                    This update adds new features to the IMDb search pane.
                    The AKA section previously used Google Translate as an API. 
                    Due to cost restrictions, this resulted in "Detection Error" in the language
                    section after a very small number of detection requests.
                    To address this, I built my own language detection API. 
                    Now there is virtually no limit on language detections.
                    </p>
                    <p className="change-description">
                    This new API also transilterates the titles. That is, all special characters
                    are automatically converted to an equivalent character that is system safe.
                    Additionally, this update now detects articles based on the language
                    and separates them into their own sections.
                    A user can now simply click on one of the AKA titles to copy each value 
                    in that row to the clipboard to paste all values from that row into the 
                    system at once. Lastly, the AKAs are sorted by language code and
                    the usual langauges (Mandarin, Russian, etc.) are filtered out.
                    </p>
                </div>
            </div>
            <div className="change-row">
                <div className="change-col-left">
                    <p className="change-date">June 20, 2024</p>
                    <p className="change-title">Bug Fix [0f3df9b]</p>
                    <p className="change-version">v1.4.1</p>
                </div>
                <div className="change-col-right">
                    <img className="changelog-img" src={v141} alt="Screenshot of update." />
                    <p className="change-description">
                    This update fixes a bug in the modified status in Dotify for productions that contain articles.
                    </p>
                    <p className="change-description">
                    Bug description (fixed): When cue sheet file names contained an article in the production title only,
                    and no other changes needed to be made, the article would be moved as expected but the 
                    "modified" indicator would not turn green.
                    </p>
                </div>
            </div>
            <div className="change-row">
                <div className="change-col-left">
                    <p className="change-date">Feb 24, 2023</p>
                    <p className="change-title">Bug Fix [a1686174]</p>
                    <p className="change-version">v1.4.0</p>
                </div>
                <div className="change-col-right">
                    <p className="change-description">
                    This update fixes a bug in the modified status in Dotify and adds support for filtering out duplicate titles
                    in IMDb AKAs.
                    </p>
                    <p className="change-description">
                    Bug description (fixed): When a cue sheet's file name extension (.pdf, .PDF, etc.) is  standardized,
                    the "modified" indicator would turn green. This is not necessary, as PC/MAC will ignore the casing
                    of file extensions.
                    </p>
                    <p className="change-description">
                    Feature: AKAs in the IMDb search module now filters out duplicate titles ignoring case.
                    </p>
                </div>
            </div>
        </div>
        </>
    );
}