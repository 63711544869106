import React, {useState, useEffect} from 'react';
import { FaRegQuestionCircle } from "react-icons/fa";

export default function RemixInput(props) {
    const [state, setState] = useState({
        from: "",
        to: ""
    });
    const [history, setHistory] = useState([]);

    const handleChange = (e) => {
        setState( prev => ({
            ...prev,
            [e.target.name]: e.target.value
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (state.from === "") return null;
        props.replace(state.from, state.to);
        setHistory( prev => ([...prev, {state, fileData: props.fileData}]));
        setState({from: "", to: ""});
    };

    const handleShortcut = (e) => {
        if ((e.ctrlKey && e.key === 'z') ||
            (e.metaKey && e.key === 'z')) {
            if (history.length) {
                props.undo(history[history.length - 1].fileData);
                setHistory( prev => {
                    return prev.length < 2 ? [] : prev.slice(0, prev.length - 1);
                });
            }
        }
    };

    useEffect(() => {
        window.addEventListener('keydown', handleShortcut);

        return () => window.removeEventListener('keydown', handleShortcut);
    }); 

    useEffect(() => {
        if (!props.fileData.length) {
            setHistory([]);
        }
    }, [props.fileData]);

    return (
        <div id="remix-input-container">
            <p className="main-container-subheader">RegEx Panel <FaRegQuestionCircle onClick={() => window.open("https://en.wikipedia.org/wiki/Regular_expression")} className="remix-help" /></p>
            <div className="component tools--input">
                <input
                    disabled={props.isFiltered || !props.fileData.length ? true : false}
                    type="text" 
                    name="from" 
                    className="remix-input" 
                    id="remix-input-from" 
                    placeholder={props.isFiltered ? "Clear Filter to Continue" : "Search" }
                    value={state.from} 
                    onChange={handleChange}
                />
                <input 
                    disabled={props.isFiltered || !props.fileData.length ? true : false}
                    type="text" 
                    name="to" 
                    className="remix-input" 
                    id="remix-input-to" 
                    placeholder={props.isFiltered ? "" : "Replace" }
                    value={state.to} 
                    onChange={handleChange}
                />
                <div className="button-container">
                    <div className="regex-history">
                        {(history.length && props.matchFound && props.fileData.length) ? 
                        (<p className="main-container-subheader">Last Change:<br />
                            <b>{history[history.length - 1].state.from}</b> 
                            &nbsp;replaced with&nbsp;
                            <b>{history[history.length - 1].state.to}</b>
                        </p>) 
                        : 
                        <p className="main-container-subheader">No Changes</p>}
                    </div>
                    <button 
                        id="regex-button" 
                        className="button-dotify" 
                        onClick={handleSubmit} 
                        disabled={props.isFiltered || state.from === "" ? true: false} 
                        type="submit">Replace</button>
                </div>
            </div>
        </div>
    );
}