const articles = {
    "en": ["a", "an", "the"],
    "es": ["el", "la", "los", "las", "un", "una", "unos", "unas"],
    "fr": ["le", "la", "les", "un", "une", "des"],
    "de": ["der", "die", "das", "ein", "eine", "einen", "einem", "einer"],
    "it": ["il", "lo", "la", "i", "gli", "le", "un", "uno", "una", "un'"],
    "pt": ["o", "a", "os", "as", "um", "uma", "uns", "umas"],
    "nl": ["de", "het", "een"],
    "sv": ["en", "ett", "den", "det", "de"],
    "no": ["en", "ei", "et", "den", "det", "de"],
    "da": ["en", "et"],
    "ro": ["un", "o", "niste", "cel", "cea", "cei", "cele"],
    "ca": ["el", "la", "els", "les", "un", "una", "uns", "unes"],
    "gl": ["o", "a", "os", "as", "un", "unha", "uns", "unhas"],
    "pl": ["ten", "ta", "to", "ci", "te", "jeden", "jedna", "jedno"],
    "cs": ["ten", "ta", "to", "jeden", "jedna", "jedno"],
    "sk": ["ten", "ta", "to", "jeden", "jedna", "jedno"],
    "sl": ["ta", "to", "tisti", "ena", "en", "eno"],
    "hr": ["taj", "ta", "to", "jedan", "jedna", "jedno"],
    "el": ["ο", "n", "to", "evas", "uia", "eva"],
    "hu": ["a", "az", "egy"],
    "tr": ["bir", "bu", "su", "o"],
    "fi": ["yksi", "se", "ne"],
    "lv": ["tas", "ta", "tie", "tās", "viens", "viena"],
    "lt": ["tas", "ta", "tie", "tos", "vienas", "viena"],
    "et": ["see", "need", "uks"],
    "co": ["u", "a", "i", "e", "un", "una", "di", "delle"]
}

export default articles