import React from 'react';

export default function DropZone(props) {
    const [fileList, setFileList] = React.useState([]);
    const [inZone, setInZone] = React.useState(false);

    const handleChange = e => {
        e.preventDefault();
        e.stopPropagation();
        setFileList([...e.target.files]);
    };

    const handleInput = e => {
        e.preventDefault();
        e.stopPropagation();
        setFileList([...e.target.files]);
    };

    const handleDragEnter = e => {
        e.preventDefault();
        e.stopPropagation();
        setInZone(true);
    };

    const handleDragLeave = e => {
        e.preventDefault();
        e.stopPropagation();
        setInZone(false);
    };

    const handleDragOver = e => {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleDrop = e => {
        e.preventDefault();
        e.stopPropagation();
        e.dataTransfer.dropEffect = 'copy';
        setFileList([...e.dataTransfer.files]);
        setInZone(false);
    };

    React.useEffect(() => {
        props.callback(fileList);
        // eslint-disable-next-line
    }, [fileList]); 

    return (
        <div id="dotify-multi">
            <p className="main-container-subheader">Batch</p>
            <div className="component" id="dotify-drop-input">
                <h2>Drag & Drop Files Here</h2>
                <svg className={`controller--icon ${inZone ? 'icon-drag-over' : ''}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                    <path d="M384 352v64c0 17.67-14.33 32-32 32H96c-17.67 0-32-14.33-32-32v-64c0-17.67-14.33-32-32-32s-32 14.33-32 32v64c0 53.02 42.98 96 96 96h256c53.02 0 96-42.98 96-96v-64c0-17.67-14.33-32-32-32S384 334.3 384 352zM201.4 9.375l-128 128c-12.51 12.51-12.49 32.76 0 45.25c12.5 12.5 32.75 12.5 45.25 0L192 109.3V320c0 17.69 14.31 32 32 32s32-14.31 32-32V109.3l73.38 73.38c12.5 12.5 32.75 12.5 45.25 0s12.5-32.75 0-45.25l-128-128C234.1-3.125 213.9-3.125 201.4 9.375z"/>
                </svg>
                <label 
                    onDrop={handleDrop}
                    onDragOver={handleDragOver}
                    onDragEnter={handleDragEnter} 
                    onDragLeave={handleDragLeave}
                    htmlFor="drop-area" 
                    id="drop-area-label">
                <input 
                    onInput={handleInput} 
                    onChange={handleChange} 
                    type="file" 
                    id="drop-area"
                    accept="application/pdf"
                    multiple />
                </label>
            </div>
        </div>
    );
}